import { useCallback, useMemo } from 'react';
import { BookOrder, BookOrderStatus, EBookOrderStatus } from '@remento/types/book-order';
import { EntityType } from '@remento/types/entity';
import { useQuery } from '@tanstack/react-query';

import { useAbortableEffect } from '@/hooks';
import { useCollection, useCollectionEntityData, useEntity } from '@/hooks/useQuery';
import { useServices } from '@/Services';

export function useBookQuery(bookId: string | null | undefined) {
  const { bookService } = useServices();
  return useEntity(EntityType.BOOK_TEMPLATE, bookId, (id, scope) => bookService.getBook(id, scope));
}

export function useProjectBooksQuery(projectId: string | null) {
  const { bookService } = useServices();
  return useCollection(EntityType.BOOK_TEMPLATE, projectId ? { projectId } : null, (params, scope) =>
    bookService.getBooksByProjectId(params.projectId, scope),
  );
}

export function useBookPreviewAssetIdQuery(bookId: string | null) {
  const { bookService, entityCacheManagerService } = useServices();
  const bookQuery = useBookQuery(bookId);
  return useQuery({
    queryKey: ['book-preview', bookId, bookQuery.data?.pdfAssetId],
    enabled: bookId != null,
    queryFn: async ({ signal }) => {
      const result = await bookService.getBookPreview(bookId ?? '', { signal });
      await entityCacheManagerService.cacheResponse(result);
      const id = 'entities' in result ? result.entities[EntityType.ASSET]?.[0].id : result[EntityType.ASSET]?.[0].id;
      return id ?? null;
    },
  });
}

export function useBookOrderQuery(bookOrderId: string | null) {
  const { bookService } = useServices();
  return useEntity(EntityType.BOOK_ORDER, bookOrderId, (id, scope) => bookService.getBookOrder(id, scope));
}

export function useProjectBookOrdersQuery(projectId: string) {
  const { bookService } = useServices();
  return useCollection(EntityType.BOOK_ORDER, projectId ? { projectId } : null, (params, scope) => {
    return bookService.getProjectBookOrders(params.projectId, scope);
  });
}

export function useFilterBookOrdersIds(ids: string[], statuses: (BookOrderStatus | EBookOrderStatus)[]) {
  const { bookService } = useServices();

  const allBookOrders = useCollectionEntityData(ids, EntityType.BOOK_ORDER, (id) => bookService.getBookOrder(id));

  return useMemo(() => {
    return allBookOrders
      .filter((bookOrder) => {
        return statuses.includes(bookOrder.status as BookOrderStatus | EBookOrderStatus);
      })
      .map((bookOrder) => bookOrder.id);
  }, [allBookOrders, statuses]);
}

export function usePollBookOrder(
  bookOrderId: string | null | undefined,
  stopCallback: (bookOrder: BookOrder) => boolean,
  interval?: number,
): void {
  const { bookService, entityCacheManagerService } = useServices();

  useAbortableEffect(
    (signal) => {
      if (bookOrderId == null) {
        return;
      }

      entityCacheManagerService.observeEntity({
        entityType: EntityType.BOOK_ORDER,
        entityId: bookOrderId,
        fetchCallback: ({ signal }) => bookService.getBookOrder(bookOrderId, { signal }),
        stopCallback,
        signal,
        interval,
      });
    },
    [entityCacheManagerService, interval, bookOrderId, bookService, stopCallback],
  );
}

export function usePollBookOrderUntilCompleted(bookOrderId: string | null | undefined, interval?: number) {
  return usePollBookOrder(
    bookOrderId,
    useCallback((o) => o.status != BookOrderStatus.AWAITING_PAYMENT, []),
    interval,
  );
}

export function usePollBookOrderUntilProcessed(bookOrderId: string | null | undefined, interval?: number) {
  return usePollBookOrder(
    bookOrderId,
    useCallback((o) => o.status != BookOrderStatus.PROCESSING, []),
    interval,
  );
}
