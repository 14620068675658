import { z } from 'zod';

import { BaseEntity, BaseEntityStatus, BaseMutation } from './base-entity';
import { EntityType } from './entity';

export enum AssetType {
  CLIENT_STATE = 'client-state',
  IMAGE = 'image',
  VIDEO = 'video',
  VIDEO_RECORDING = 'video-recording',
  AUDIO_RECORDING = 'audio-recording',
  PDF_DOCUMENT = 'pdf-document',
}

export enum AssetStatus {
  UPLOAD_PENDING = 'upload-pending',
  ENTITY_PENDING = 'entity-pending',
  ACTIVE = 'active',
}

export enum AssetProcessingStatus {
  PROCESS_PENDING = 'process-pending',
  PROCESSING = 'processing',
  PROCESS_FAILED = 'process-failed',
  PROCESSED = 'processed',
}

export interface BaseAsset extends BaseEntity<AssetStatus> {
  processingStatus: AssetProcessingStatus;
  backRef: Array<{ type: EntityType; id: string }>;
  workerId?: string | null;
  acl: string[];
}

export interface ClientStateAsset extends BaseAsset {
  type: AssetType.CLIENT_STATE;
}

export interface ImageAsset extends BaseAsset {
  type: AssetType.IMAGE;
}

export interface VideoAsset extends BaseAsset {
  type: AssetType.VIDEO;
}

export interface VideoRecordingAsset extends BaseAsset {
  type: AssetType.VIDEO_RECORDING;
}

export interface AudioRecordingAsset extends BaseAsset {
  type: AssetType.AUDIO_RECORDING;
}

export interface PdfDocumentAsset extends BaseAsset {
  type: AssetType.PDF_DOCUMENT;
}

export type Asset =
  | ClientStateAsset
  | ImageAsset
  | VideoAsset
  | VideoRecordingAsset
  | AudioRecordingAsset
  | PdfDocumentAsset;

export enum AssetMutationType {
  SET_STATUS = 'set-status',
  SET_PROCESSING_STATUS = 'set-processing-status',
  LINK_ENTITY = 'link-entity',
  UNLINK_ENTITY = 'unlink-entity',
  SET_WORKER_ID = 'set-worker-id',
}

export type SetAssetStatusMutation = BaseMutation<AssetMutationType.SET_STATUS, BaseEntityStatus | AssetStatus>;
export type SetAssetProcessingStatusMutation = BaseMutation<
  AssetMutationType.SET_PROCESSING_STATUS,
  AssetProcessingStatus
>;
export type LinkEntityMutation = BaseMutation<
  AssetMutationType.LINK_ENTITY,
  { entity: { type: EntityType; id: string }; acl: string[] }
>;
export type UnlinkEntityMutation = BaseMutation<AssetMutationType.UNLINK_ENTITY, { type: EntityType; id: string }>;
export type SetAssetWorkerIdMutation = BaseMutation<AssetMutationType.SET_WORKER_ID, string>;

export type AssetMutation =
  | SetAssetStatusMutation
  | SetAssetProcessingStatusMutation
  | LinkEntityMutation
  | UnlinkEntityMutation
  | SetAssetWorkerIdMutation;

export const AssetMutationSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(AssetMutationType.UNLINK_ENTITY),
    value: z.object({
      type: z.nativeEnum(EntityType),
      id: z.string(),
    }),
    vclock: z.number(),
    version: z.number(),
  }),
]);
