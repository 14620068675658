import { Page } from '@/services/analytics/analytics.types';

import { RoutePath } from './routing.types';

export const RoutePageEventMap: Record<RoutePath, Page | null> = {
  // Generic
  [RoutePath.Root]: null,
  [RoutePath.InApp]: 'in-app',
  [RoutePath.Signin]: 'sign-in',
  [RoutePath.Signout]: 'sign-out',
  [RoutePath.AccountName]: 'account-name-setup',

  [RoutePath.StoryStandalone]: 'story-standalone',
  [RoutePath.StoryHighlightReel]: 'story-highlight-reel-standalone',
  [RoutePath.Story]: 'story',
  [RoutePath.Stories]: 'stories',

  [RoutePath.Questions]: 'upcoming-prompts',
  [RoutePath.ProjectSettings]: 'project-settings',

  [RoutePath.StoriesLegacyRedirect]: null,
  [RoutePath.QuestionsLegacyRedirect]: null,
  [RoutePath.ProjectSettingsLegacyRedirect]: null,
  [RoutePath.ProjectStoriesRedirect]: null,
  [RoutePath.ProjectQuestionsRedirect]: null,
  [RoutePath.ProjectSettingsRedirect]: null,

  // Account and subscription
  [RoutePath.AccountSettings]: 'my-account-settings',
  [RoutePath.AccountRenewPromo]: 'account-renew-promo',
  [RoutePath.AccountReactivate]: 'account-reactivate',
  [RoutePath.SubscriptionRenewalSucceeded]: 'subscription-renewal-succeeded',
  [RoutePath.SubscriptionCancelledSurvey]: 'subscription-cancelled-survey',

  // Recording
  [RoutePath.RecordingIntro]: 'recording-intro',
  [RoutePath.RecordingTypeSelection]: 'recording-type-selection',
  [RoutePath.Recording]: 'recording',
  [RoutePath.RecordingPromptRecorded]: 'recording-prompt-already-recorded',
  [RoutePath.RecordingRecovery]: 'recording-recovery',
  [RoutePath.RecordingFinish]: 'recording-finish',
  [RoutePath.ProjectRecord]: 'project-record',
  [RoutePath.ProjectRecordCustomize]: 'project-record-customize',

  // Checkout routes
  [RoutePath.Checkout]: 'checkout',
  [RoutePath.CheckoutAudience]: 'checkout-audience',
  [RoutePath.CheckoutBiographyContact]: 'checkout-biography-contact',
  [RoutePath.CheckoutBiographyDelivery]: 'checkout-biography-delivery',
  [RoutePath.CheckoutBiographyReview]: 'checkout-biography-review',
  [RoutePath.CheckoutAutobiographyContact]: 'checkout-autobiography-contact',
  [RoutePath.CheckoutAutobiographyReview]: 'checkout-autobiography-review',
  [RoutePath.CheckoutBaby]: 'checkout-baby',
  [RoutePath.CheckoutBabyAudience]: 'checkout-baby-audience',
  [RoutePath.CheckoutBabyHost]: 'checkout-baby-host',
  [RoutePath.CheckoutBabyProject]: 'checkout-baby-project',
  [RoutePath.CheckoutBabyReview]: 'checkout-baby-review',
  [RoutePath.CheckoutFreeDetails]: 'checkout-free-details',
  [RoutePath.CheckoutFreeReview]: 'checkout-free-review',
  [RoutePath.CheckoutSurvey]: 'checkout-survey',
  [RoutePath.CheckoutSuccess]: 'checkout-success',
  [RoutePath.CheckoutGetStarted]: 'checkout-get-started',
  [RoutePath.NewProject]: 'new-project',

  // Onboarding
  [RoutePath.Onboarding]: 'onboarding',
  [RoutePath.OnboardingSignin]: 'onboarding-sign-in',
  [RoutePath.OnboardingIntro]: 'onboarding-intro',

  // Setup
  [RoutePath.SetupPromptTypeSelection]: 'setup-prompt-type-selection',
  [RoutePath.SetupTextPromptSelection]: 'setup-text-prompt-selection',
  [RoutePath.SetupPromptsReview]: 'setup-prompts-review',
  [RoutePath.SetupPerspective]: 'setup-perspective',
  [RoutePath.SetupCollaborators]: 'setup-collaborators',

  // Legacy onboarding
  [RoutePath.LegacyOnboardingFinish]: 'legacy-onboarding-finish',

  // Other
  [RoutePath.Welcome]: 'welcome-video',

  // Invite
  [RoutePath.Invite]: 'invite',
  [RoutePath.InviteSignin]: 'invite-sign-in',

  // Order Book
  [RoutePath.BookOrderCoverDesign]: 'book-order-cover-design',
  [RoutePath.BookOrderStories]: 'book-order-stories',
  [RoutePath.BookOrderPreview]: 'book-order-preview',
  [RoutePath.BookOrderQuantity]: 'book-order-quantity',
  [RoutePath.BookOrderFinalize]: 'book-order-finalize',
  [RoutePath.BookOrderComplete]: 'book-order-complete',
  [RoutePath.BookReorderPreview]: 'book-reorder-preview',
  [RoutePath.BookReorderQuantity]: 'book-reorder-quantity',
  [RoutePath.BookReorderFinalize]: 'book-reorder-finalize',

  // Poll
  [RoutePath.Poll]: 'poll',

  // Questionnaire
  [RoutePath.QuestionnaireRoot]: 'questionnaire-root',
  [RoutePath.Questionnaire]: 'questionnaire',

  // Debug
  [RoutePath.DebugDeleteAccount]: 'debug-delete-account',

  // PDF
  [RoutePath.BookCover]: null,
  [RoutePath.BookGutsCover]: null,
  [RoutePath.BookTableOfContents]: null,
  [RoutePath.BookStoryContent]: null,

  // Legacy Routes
  [RoutePath.LegacyCheckoutAudience]: 'legacy-checkout-audience',
  [RoutePath.LegacySeriesSettings]: 'legacy-series-settings',
  [RoutePath.LegacySeriesSettingsRedirect]: 'legacy-series-settings-redirect',
  [RoutePath.LegacyStoryStandalone]: 'legacy-story-standalone',
  [RoutePath.LegacyStory]: 'legacy-story',
  [RoutePath.LegacyQuestions]: 'legacy-questions',
  [RoutePath.LegacyRecordingIntro]: 'legacy-recording-intro',
  [RoutePath.LegacyRecordingTypeSelection]: 'legacy-recording-type-selection',
  [RoutePath.LegacyRecording]: 'legacy-recording',
  [RoutePath.LegacyRecordingPromptRecorded]: 'legacy-recording-prompt-already-recorded',
  [RoutePath.LegacyAccountSettings]: 'legacy-account-settings',

  // Broken email recovery
  [RoutePath.BrokenEmailRecovery_Question]: 'broken-questions-email-recovery',
};
