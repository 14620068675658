import { useMemo } from 'react';
import { EntityType } from '@remento/types/entity';
import { NotFoundError } from '@remento/types/error';
import { Person } from '@remento/types/person';
import { Project } from '@remento/types/project';
import { UserSubscriptionStatus } from '@remento/types/user';

import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePeople } from '@/services/api/person';
import { useProjectsItems } from '@/services/api/project';

export interface StorytellerOption {
  label: string;
  value: string;
  personId: string;
  subscriptionOwnerPersonId: string;
}

export function isPersonSubscriptionActive(ownerPerson: Person, projects: Project[]): boolean {
  return (
    projects.findIndex(
      (p) =>
        ownerPerson.refIds.includes(p.ownerPersonId) &&
        (p.subscriptionStatus == UserSubscriptionStatus.ACTIVE ||
          p.subscriptionStatus == UserSubscriptionStatus.PENDING),
    ) >= 0
  );
}

export function useStorytellerOptions(): StorytellerOption[] | null {
  // Queries
  const user = useUser();
  const projects = useProjectsItems();
  const personIds = useMemo(() => {
    if (user == null) {
      return null;
    }
    return Array.from(
      new Set(
        user.storytellers
          .map((s) => [s.personId, s.subscriptionOwnerPersonId])
          .flat()
          .concat(user.personId),
      ),
    );
  }, [user]);
  const people = usePeople(personIds);

  // State
  return useMemo<StorytellerOption[] | null>(() => {
    if (user == null) {
      return null;
    }

    const userPerson = people.find((p) => p.refIds.includes(user.personId));
    if (userPerson == null) {
      throw new NotFoundError('person-not-found', {
        origin: 'entity',
        entityType: EntityType.PERSON,
        entityId: user.personId,
      });
    }

    // Filter out storytellers that don't have a valid subscription
    const validStorytellers = user.storytellers.filter((s) => {
      const ownerPerson = people.find((p) => p.refIds.includes(s.subscriptionOwnerPersonId));
      if (ownerPerson == null) {
        throw new NotFoundError('owner-person-not-found', {
          origin: 'entity',
          entityType: EntityType.PERSON,
          entityId: s.subscriptionOwnerPersonId,
        });
      }
      return isPersonSubscriptionActive(ownerPerson, projects);
    });

    const storytellersMap = new Map<string, StorytellerOption>();

    for (const storyteller of validStorytellers) {
      const storytellerPerson = people.find((p) => p.refIds.includes(storyteller.personId));
      const ownerPerson = people.find((p) => p.refIds.includes(storyteller.subscriptionOwnerPersonId));
      const isOwner = userPerson.refIds.includes(storyteller.subscriptionOwnerPersonId);
      const suffix = userPerson.refIds.includes(storyteller.personId) ? ' (me)' : '';

      let label = `${storytellerPerson?.name?.full}${suffix}`;
      if (isOwner == false) {
        label += ` - owned by ${ownerPerson?.name?.full}`;
      }

      storytellersMap.set(`${storyteller.personId}-${storyteller.subscriptionOwnerPersonId}`, {
        value: `${storyteller.personId}-${storyteller.subscriptionOwnerPersonId}`,
        label,
        personId: storyteller.personId,
        subscriptionOwnerPersonId: storyteller.subscriptionOwnerPersonId,
      });
    }

    return Array.from(storytellersMap.values()).sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
  }, [people, projects, user]);
}
