import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { toast } from '@/components/RMToast/RMToast';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery } from '@/services/api/project';

import { getAccountReactivatePath } from '../../routing';
import { PaywallDialog } from '../components/PaywallDialog';
import { closePaywall, usePaywallState } from '../paywall.state';

export function PaywallContainer() {
  // Stores
  const navigate = useNavigate();
  const { isPaywallVisible, projectId } = usePaywallState();

  // Queries
  const user = useUser();
  const personQuery = usePersonQuery(user?.personId);
  const projectQuery = useProjectQuery(projectId);
  const storytellerPersonQuery = usePersonQuery(projectQuery.data?.notifications.recipientPersonIds[0]);

  // States
  const isBillingOwner = useMemo(
    () => user?.refIds.includes(projectQuery.data?.ownerUserId ?? ''),
    [projectQuery.data?.ownerUserId, user?.refIds],
  );
  const isStoryteller = useMemo(
    () => personQuery.data?.refIds.includes(projectQuery.data?.notifications.recipientPersonIds[0] ?? ''),
    [personQuery.data?.refIds, projectQuery.data?.notifications.recipientPersonIds],
  );
  const isCollaboratorOnly = isBillingOwner == false && isStoryteller == false;

  const handleReactivate = useCallback(() => {
    closePaywall();

    if (isBillingOwner) {
      navigate(getAccountReactivatePath());
      return;
    }

    const email = 'support@remento.co';
    const subject = 'Reactivate Remento account';
    const body = [
      'Hi Remento Customer Support,',
      '',
      `I'm reaching out to reactivate the following Remento storyteller's account: ${storytellerPersonQuery.data?.name?.full}.`,
      '',
      'Can you please send me instructions on how to do so?',
    ].join('\n');

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink);
  }, [isBillingOwner, navigate, storytellerPersonQuery.data?.name?.full]);

  // Show the toast to collaborators
  useEffect(() => {
    if (isPaywallVisible == false || isCollaboratorOnly == false) {
      return;
    }

    toast('This feature is available for projects with active subscription.', 'root-toast', 'default', {
      toastId: 'paywall-toast',
      onClose: () => {
        closePaywall();
      },
    });
  }, [isCollaboratorOnly, isPaywallVisible]);

  if (isPaywallVisible == false || isCollaboratorOnly) {
    return null;
  }

  return (
    <PaywallDialog isBillingOwner={isBillingOwner ?? false} onReactivate={handleReactivate} onClose={closePaywall} />
  );
}
