import { lazy } from 'react';

export const AccountNamePage = lazy(() =>
  import('./pages/AccountNamePage/AccountNamePage').then((imports) => ({ default: imports.AccountNamePage })),
);

// Account and subscription
export const AccountSettingsPage = lazy(() =>
  import('./pages/AccountSettingsPage/AccountSettingsPage').then((imports) => ({
    default: imports.AccountSettingsPage,
  })),
);
export const AccountRenewPromoPage = lazy(() =>
  import('./modules/routing/chunks/subscription.chunks').then((imports) => ({
    default: imports.AccountRenewPromoPage,
  })),
);
export const AccountReactivatePage = lazy(() =>
  import('./modules/routing/chunks/subscription.chunks').then((imports) => ({
    default: imports.AccountReactivatePage,
  })),
);
export const SubscriptionCancelledSurveyPage = lazy(() =>
  import('./modules/routing/chunks/subscription.chunks').then((imports) => ({
    default: imports.SubscriptionCancelledSurveyPage,
  })),
);
export const SubscriptionRenewalSucceededPage = lazy(() =>
  import('./modules/routing/chunks/subscription.chunks').then((imports) => ({
    default: imports.SubscriptionRenewalSucceededPage,
  })),
);

// Book
export const BookOrderLayoutContainer = lazy(() =>
  import('./modules/routing/chunks/book.chunks').then((imports) => ({
    default: imports.BookOrderLayoutContainer,
  })),
);
export const BookOrderCoverDesignPage = lazy(() =>
  import('./modules/routing/chunks/book.chunks').then((imports) => ({
    default: imports.BookOrderCoverDesignPage,
  })),
);
export const BookOrderStoriesPage = lazy(() =>
  import('./modules/routing/chunks/book.chunks').then((imports) => ({
    default: imports.BookOrderStoriesPage,
  })),
);
export const BookOrderFinalizePage = lazy(() =>
  import('./modules/routing/chunks/book.chunks').then((imports) => ({
    default: imports.BookOrderFinalizePage,
  })),
);
export const BookOrderCompletePage = lazy(() =>
  import('./modules/routing/chunks/book.chunks').then((imports) => ({
    default: imports.BookOrderCompletePage,
  })),
);
export const BookOrderPreviewPage = lazy(() =>
  import('./modules/routing/chunks/book.chunks').then((imports) => ({
    default: imports.BookOrderPreviewPage,
  })),
);
export const BookOrderQuantityPage = lazy(() =>
  import('./modules/routing/chunks/book.chunks').then((imports) => ({
    default: imports.BookOrderQuantityPage,
  })),
);

// Book reorder
export const BookReorderLayoutContainer = lazy(() =>
  import('./modules/routing/chunks/book.chunks').then((imports) => ({
    default: imports.BookReorderLayoutContainer,
  })),
);
export const BookReorderPreviewPage = lazy(() =>
  import('./modules/routing/chunks/book.chunks').then((imports) => ({
    default: imports.BookReorderPreviewPage,
  })),
);

export const BookReorderQuantityPage = lazy(() =>
  import('./modules/routing/chunks/book.chunks').then((imports) => ({
    default: imports.BookReorderQuantityPage,
  })),
);

export const BookReorderFinalizePage = lazy(() =>
  import('./modules/routing/chunks/book.chunks').then((imports) => ({
    default: imports.BookReorderFinalizePage,
  })),
);

// Common checkout
export const ProjectCheckoutPage = lazy(() =>
  import('./modules/routing/chunks/project-checkout.chunks').then((imports) => ({
    default: imports.ProjectCheckoutPage,
  })),
);
export const ProjectCheckoutAudiencePage = lazy(() =>
  import('./modules/routing/chunks/project-checkout.chunks').then((imports) => ({
    default: imports.ProjectCheckoutAudiencePage,
  })),
);
export const ProjectCheckoutContainer = lazy(() =>
  import('./modules/routing/chunks/project-checkout.chunks').then((imports) => ({
    default: imports.ProjectCheckoutContainer,
  })),
);
export const AutobiographyProjectCheckoutContactStepContainer = lazy(() =>
  import('./modules/routing/chunks/project-checkout.chunks').then((imports) => ({
    default: imports.AutobiographyProjectCheckoutContactStepContainer,
  })),
);
export const AutobiographyProjectCheckoutReviewStepContainer = lazy(() =>
  import('./modules/routing/chunks/project-checkout.chunks').then((imports) => ({
    default: imports.AutobiographyProjectCheckoutReviewStepContainer,
  })),
);
export const BabybookProjectCheckoutBookStepContainer = lazy(() =>
  import('./modules/routing/chunks/project-checkout.chunks').then((imports) => ({
    default: imports.BabybookProjectCheckoutBookStepContainer,
  })),
);
export const BabybookProjectCheckoutContactStepContainer = lazy(() =>
  import('./modules/routing/chunks/project-checkout.chunks').then((imports) => ({
    default: imports.BabybookProjectCheckoutContactStepContainer,
  })),
);
export const BabybookProjectCheckoutReviewStepContainer = lazy(() =>
  import('./modules/routing/chunks/project-checkout.chunks').then((imports) => ({
    default: imports.BabybookProjectCheckoutReviewStepContainer,
  })),
);
export const BiographyProjectCheckoutContactStepContainer = lazy(() =>
  import('./modules/routing/chunks/project-checkout.chunks').then((imports) => ({
    default: imports.BiographyProjectCheckoutContactStepContainer,
  })),
);
export const BiographyProjectCheckoutDeliveryStepContainer = lazy(() =>
  import('./modules/routing/chunks/project-checkout.chunks').then((imports) => ({
    default: imports.BiographyProjectCheckoutDeliveryStepContainer,
  })),
);
export const BiographyProjectCheckoutReviewStepContainer = lazy(() =>
  import('./modules/routing/chunks/project-checkout.chunks').then((imports) => ({
    default: imports.BiographyProjectCheckoutReviewStepContainer,
  })),
);
export const FreeProjectCheckoutDetailsStepContainer = lazy(() =>
  import('./modules/routing/chunks/project-checkout.chunks').then((imports) => ({
    default: imports.FreeProjectCheckoutDetailsStepContainer,
  })),
);
export const FreeProjectCheckoutReviewStepContainer = lazy(() =>
  import('./modules/routing/chunks/project-checkout.chunks').then((imports) => ({
    default: imports.FreeProjectCheckoutReviewStepContainer,
  })),
);
export const ProjectCheckoutGetStartedPage = lazy(() =>
  import('./modules/routing/chunks/project-checkout.chunks').then((imports) => ({
    default: imports.ProjectCheckoutGetStartedPage,
  })),
);
export const ProjectCheckoutSuccessPage = lazy(() =>
  import('./modules/routing/chunks/project-checkout.chunks').then((imports) => ({
    default: imports.ProjectCheckoutSuccessPage,
  })),
);
export const ProjectCheckoutSurveyPage = lazy(() =>
  import('./modules/routing/chunks/project-checkout.chunks').then((imports) => ({
    default: imports.ProjectCheckoutSurveyPage,
  })),
);
export const NewProjectPage = lazy(() =>
  import('./modules/routing/chunks/project-checkout.chunks').then((imports) => ({
    default: imports.NewProjectPage,
  })),
);

// --
export const DebugDeleteAccountPage = lazy(() =>
  import('./pages/DebugDeleteAccountPage/DebugDeleteAccountPage').then((imports) => ({
    default: imports.DebugDeleteAccountPage,
  })),
);
export const InAppPage = lazy(() =>
  import('./pages/InAppPage/InAppPage').then((imports) => ({ default: imports.InAppPage })),
);
export const InvitePage = lazy(() =>
  import('./pages/InvitePage/InvitePage').then((imports) => ({ default: imports.InvitePage })),
);
export const LegacyOnboardingFinishPage = lazy(() =>
  import('./pages/LegacyOnboardingFinishPage/LegacyOnboardingFinishPage').then((imports) => ({
    default: imports.LegacyOnboardingFinishPage,
  })),
);
export const OnboardingIntroPage = lazy(() =>
  import('./pages/OnboardingIntroPage/OnboardingIntroPage').then((imports) => ({
    default: imports.OnboardingIntroPage,
  })),
);
export const OnboardingSigninPage = lazy(() =>
  import('./pages/OnboardingSigninPage/OnboardingSigninPage').then((imports) => ({
    default: imports.OnboardingSigninPage,
  })),
);
export const ProjectSettingsPage = lazy(() =>
  import('./pages/ProjectSettingsPage/ProjectSettingsPage').then((imports) => ({
    default: imports.ProjectSettingsPage,
  })),
);
export const QuestionsPage = lazy(() =>
  import('./pages/QuestionsPage/QuestionsPage').then((imports) => ({ default: imports.QuestionsPage })),
);

// Recording
export const RecordingIntroPage = lazy(() =>
  import('./modules/routing/chunks/recording.chunks').then((imports) => ({ default: imports.RecordingIntroPage })),
);
export const RecordingPageContainer = lazy(() =>
  import('./modules/routing/chunks/recording.chunks').then((imports) => ({ default: imports.RecordingPageContainer })),
);
export const RecordingPromptRecordedPage = lazy(() =>
  import('./modules/routing/chunks/recording.chunks').then((imports) => ({
    default: imports.RecordingPromptRecordedPage,
  })),
);
export const RecordingRecoveryPage = lazy(() =>
  import('./modules/routing/chunks/recording.chunks').then((imports) => ({
    default: imports.RecordingRecoveryPage,
  })),
);
export const RecordingFinishPage = lazy(() =>
  import('./modules/routing/chunks/recording.chunks').then((imports) => ({
    default: imports.RecordingFinishPage,
  })),
);
export const RecordingTypeSelectionPageContainer = lazy(() =>
  import('./modules/routing/chunks/recording.chunks').then((imports) => ({
    default: imports.RecordingTypeSelectionPageContainer,
  })),
);
export const ProjectRecordPage = lazy(() =>
  import('./modules/routing/chunks/recording.chunks').then((imports) => ({
    default: imports.ProjectRecordPage,
  })),
);
export const ProjectRecordCustomizePage = lazy(() =>
  import('./modules/routing/chunks/recording.chunks').then((imports) => ({
    default: imports.ProjectRecordCustomizePage,
  })),
);

// Setup
export const SetupPromptsReviewPage = lazy(() =>
  import('./modules/routing/chunks/setup.chunks').then((imports) => ({
    default: imports.SetupPromptsReviewPage,
  })),
);
export const SetupPromptTypeSelectionPage = lazy(() =>
  import('./modules/routing/chunks/setup.chunks').then((imports) => ({
    default: imports.SetupPromptTypeSelectionPage,
  })),
);
export const SetupTextPromptSelectionPage = lazy(() =>
  import('./modules/routing/chunks/setup.chunks').then((imports) => ({
    default: imports.SetupTextPromptSelectionPage,
  })),
);
export const SetupPerspectivePage = lazy(() =>
  import('./modules/routing/chunks/setup.chunks').then((imports) => ({
    default: imports.SetupPerspectivePage,
  })),
);
export const SetupCollaboratorsPage = lazy(() =>
  import('./modules/routing/chunks/setup.chunks').then((imports) => ({
    default: imports.SetupCollaboratorsPage,
  })),
);

// Poll
export const PollPage = lazy(() =>
  import('./modules/routing/chunks/poll.chunks').then((imports) => ({
    default: imports.PollPage,
  })),
);

// Questionnaire
export const QuestionnairePage = lazy(() =>
  import('./modules/routing/chunks/questionnaire.chunks').then((imports) => ({
    default: imports.QuestionnairePage,
  })),
);

// --
export const SigninPage = lazy(() =>
  import('./pages/SigninPage/SigninPage').then((imports) => ({ default: imports.SigninPage })),
);
export const StoriesPage = lazy(() =>
  import('./pages/StoriesPage/StoriesPage').then((imports) => ({ default: imports.StoriesPage })),
);
export const StoryHighlightReelPage = lazy(() =>
  import('./pages/StoryHighlightReelPage/StoryHighlightReelPage').then((imports) => ({
    default: imports.StoryHighlightReelPage,
  })),
);

export const StoryPage = lazy(() =>
  import('./pages/StoryPage/StoryPage').then((imports) => ({ default: imports.StoryPage })),
);
export const WelcomePage = lazy(() =>
  import('./pages/WelcomePage/WelcomePage').then((imports) => ({ default: imports.WelcomePage })),
);

// Pdf
export const BookStoryContent = lazy(() =>
  import('./pages/@pdf/Book/BookStoryContent').then((imports) => ({ default: imports.BookStoryContent })),
);
export const BookCover = lazy(() =>
  import('./pages/@pdf/Book/BookCover').then((imports) => ({ default: imports.BookCover })),
);
export const BookGutsCover = lazy(() =>
  import('./pages/@pdf/Book/BookGutsCover').then((imports) => ({ default: imports.BookGutsCover })),
);
export const BookTableOfContents = lazy(() =>
  import('./pages/@pdf/Book/BookTableOfContents').then((imports) => ({ default: imports.BookTableOfContents })),
);
