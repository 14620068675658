import Dexie from 'dexie';

import { getLocalStoreKey, LocalStoreRepository } from '../../../services/local/local-store';

import { InternalQueueItem, PartitionQueueRepository, StringKey } from './partition-queue.types';

export interface IndexedQueueItem<T, P extends string = string> extends InternalQueueItem<T, P> {
  sessionId: string;
}

class PartitionQueueDexieDatabase<T, P extends string = string> extends Dexie {
  readonly queue!: Dexie.Table<IndexedQueueItem<T, P>>;

  constructor() {
    super('PartitionQueueDatabase', {
      chromeTransactionDurability: 'strict',
    });

    // TODO - Review these indexes
    this.version(1).stores({
      queue: `[sessionId+priority+sequenceId], [sessionId+partition+sequenceId], [partition+sessionId] `,
    });
  }
}

// TODO - I think we could improve the generic typing in this file.

export class IdbPartitionQueueRepository<T extends Record<string, unknown>> implements PartitionQueueRepository<T> {
  private readonly db = new PartitionQueueDexieDatabase();

  constructor(private localStore: LocalStoreRepository) {}

  async addQueueItem<P extends StringKey<T>>(sessionId: string, item: InternalQueueItem<T[P], P>): Promise<void> {
    const latestSequenceId = this.getLatestSequenceId(sessionId, item.partition);
    if (latestSequenceId !== null && latestSequenceId >= item.sequenceId) {
      throw new Error('Cannot push a new item with a lower sequenceId than a previous pushed item');
    }

    await this.db.queue.put({
      ...item,
      sessionId,
    });
    this.setLatestSequenceId(sessionId, item.partition, item.sequenceId);
  }

  async removeQueueItem<P extends StringKey<T>>(sessionId: string, partition: P, sequenceId: number): Promise<void> {
    await this.db.queue
      .where(['sessionId', 'partition', 'sequenceId'])
      .equals([sessionId, partition, sequenceId])
      .delete();
  }

  async getNextQueueItem<P extends StringKey<T>>(
    sessionId: string,
    offset?: number,
  ): Promise<InternalQueueItem<T[P], P> | null> {
    const item = await this.db.queue
      .where('sessionId')
      .equals(sessionId)
      .offset(offset ?? 0)
      .first();
    if (item) {
      return item as unknown as InternalQueueItem<T[P], P>;
    }
    return null;
  }

  async clear(sessionId: string): Promise<void> {
    await this.db.queue.where('sessionId').equals(sessionId).delete();
    this.deleteLatestSequenceIds(sessionId);
  }

  async size<P extends StringKey<T>>(sessionId: string, partition?: P): Promise<number> {
    if (partition) {
      return this.db.queue.where(['partition', 'sessionId']).equals([partition, sessionId]).count();
    }

    return this.db.queue.where('sessionId').equals(sessionId).count();
  }

  private getLatestSequenceIdKey(sessionId: string): string {
    return getLocalStoreKey(['@remento/partition-queue', 'session', sessionId, 'latest-sequence-id']);
  }

  getLatestSequenceId<P extends StringKey<T>>(sessionId: string, partition: P): number | null {
    const currentData = this.localStore.getItem<Partial<Record<P, number>>>(this.getLatestSequenceIdKey(sessionId));
    return currentData?.[partition] ?? null;
  }

  private setLatestSequenceId<P extends StringKey<T>>(sessionId: string, partition: P, sequenceId: number): void {
    const key = this.getLatestSequenceIdKey(sessionId);
    const currentData = this.localStore.getItem<Partial<Record<P, number>>>(key);
    this.localStore.setItem<Partial<Record<P, number>>>(key, {
      ...currentData,
      [partition]: sequenceId,
    });
  }

  private deleteLatestSequenceIds(sessionId: string): void {
    this.localStore.removeItem(this.getLatestSequenceIdKey(sessionId));
  }
}
