import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@linaria/react';

import { RMDialog } from '@/components/RMDialog';

export const Header = styled(RMDialog.Header)`
  padding-bottom: 0;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-lg);

  body[data-mobile='false'] & {
    padding-bottom: calc(var(--spacing-sm) + 3rem - var(--spacing-xl));
  }
`;

export const Image = styled.img`
  max-width: 262px;
  width: 100%;
  border-radius: var(--radius-round);
  object-fit: cover;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
`;

export const FeaturesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  padding-left: var(--spacing-sm);
  padding-bottom: var(--spacing-sm);
`;

export const FeaturesListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-xs);
`;

export const FeaturesListItemIcon = styled(FontAwesomeIcon)`
  margin-top: 0;
  color: var(--on-surface-primary);
`;
