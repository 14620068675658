import { Project, ProjectStatus } from '@remento/types/project';
import { SubscriptionRenewalType, User, UserOnboardingActionType, UserSubscriptionStatus } from '@remento/types/user';
import dayjs from 'dayjs';

import { LocalStoreRepository } from '@/services/local/local-store';
import { genitiveCase } from '@/utils/genitiveCase';

import { PersonCacheService } from '../person';
import { UserService } from '../user/user.types';

import { ProjectBanner, ProjectBannerService, ProjectBannerType } from './project-banner.types';

export const PROJECT_BANNER_STORE_PREFIX = '@remento/project-banners';

export class DefaultProjectBannerService implements ProjectBannerService {
  constructor(
    private personCacheService: PersonCacheService,
    private userService: UserService,
    private localStore: LocalStoreRepository,
  ) {}

  private getLocalStoreClosedKey(type: ProjectBannerType): string {
    return `${type}-closed`;
  }

  // This method should receive the full entities so we can guarantee in the hook
  // that this method will be re-run when the entity changes.
  async getProjectBanner(user: User, project: Project, pendingPromptsCount: number): Promise<ProjectBanner | null> {
    const storytellerPerson = await this.personCacheService.getPerson(project.subjectPersonIds[0]);
    const ownerPerson = await this.personCacheService.getPerson(project.ownerPersonId);
    const isBillingOwner = user.refIds.includes(project.ownerUserId);
    const isStoryteller = storytellerPerson?.refIds.includes(project.notifications.recipientPersonIds[0]);

    // Subscription will be cancelled, but still active.
    // Show banner to the billing owner.
    if (
      isBillingOwner &&
      user.subscription?.renewalType === SubscriptionRenewalType.CANCEL &&
      project.subscriptionStatus === UserSubscriptionStatus.ACTIVE
    ) {
      const closed = this.localStore.getItem<boolean>(
        this.getLocalStoreClosedKey(ProjectBannerType.SUBSCRIPTION_WILL_NOT_RENEW),
      );

      if (closed != true) {
        return {
          type: ProjectBannerType.SUBSCRIPTION_WILL_NOT_RENEW,
          message: `Your subscription will expire on ${dayjs(project.endsOn).format('MM/DD/YYYY')}.`,
          actionLabel: 'Rejoin with this special offer',
          style: 'danger',
          closeable: true,
        };
      }
    }

    // Subscription is inactivate and has not been renewed.
    // Show banner to the billing owner and storyteller.
    if ((isBillingOwner || isStoryteller) && project.subscriptionStatus === UserSubscriptionStatus.INACTIVE) {
      const closed = this.localStore.getItem<boolean>(
        this.getLocalStoreClosedKey(ProjectBannerType.SUBSCRIPTION_INACTIVE),
      );

      if (closed != true) {
        let message = `${genitiveCase(ownerPerson?.name?.full)} subscription has ended.`;
        if (isBillingOwner) {
          message = 'Your subscription has ended.';
        }
        return {
          type: ProjectBannerType.SUBSCRIPTION_INACTIVE,
          message,
          actionLabel: 'Reactivate your subscription',
          style: 'info',
          closeable: true,
        };
      }
    }

    // Subscription is still active and will renew in less than 30 days.
    // Show banner to the billing owner.
    if (
      isBillingOwner &&
      project.subscriptionStatus === UserSubscriptionStatus.ACTIVE &&
      project.endsOn - Date.now() <= 30 * 24 * 60 * 60 * 1000
    ) {
      if ((user.onboardingHistory.subscriptionWillExpireBannerClosed?.done ?? false) == false) {
        return {
          type: ProjectBannerType.SUBSCRIPTION_WILL_EXPIRE,
          message: `Heads up! Your subscription is set to renew on ${dayjs(project.endsOn).format('MM/DD/YYYY')}.`,
          actionLabel: 'View your limited time offer.',
          onboardingActionType: UserOnboardingActionType.SUBSCRIPTION_WILL_EXPIRE_BANNER_CLOSED,
          closeable: true,
        };
      }
    }

    // If the project is inactive, don't show any other banner
    if (project.subscriptionStatus === UserSubscriptionStatus.INACTIVE) {
      return null;
    }

    // Project is paused.
    // Show banner to all collaborators.
    if (project.status === ProjectStatus.PAUSED) {
      return {
        type: ProjectBannerType.PROJECT_PAUSED,
        message: `${storytellerPerson?.name?.first} is not currently receiving prompts.`,
        actionLabel: 'Resume prompts',
      };
    }

    // Project needs a new prompt.
    // Show banner to all collaborators.
    if (pendingPromptsCount == 0 && project.configuration.timePeriod !== 'PRESENT') {
      return {
        type: ProjectBannerType.MISSING_PROMPT,
        message: `${storytellerPerson?.name?.first} needs a new prompt for their next session.`,
      };
    }

    return null;
  }

  async closeProjectBanner(banner: ProjectBanner): Promise<void> {
    if (banner.closeable == false) {
      throw new Error('banner-is-not-closeable');
    }

    if (banner.onboardingActionType != null) {
      await this.userService.updateOnboardingHistory(banner.onboardingActionType);
      return;
    }

    this.localStore.setItem(this.getLocalStoreClosedKey(banner.type), true);
  }
}
