import { useLayoutEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation } from 'react-router-dom';

import { isFullScreen, observeIsFullscreen } from '@/hooks/useIsFullscreen';
import { isMobileViewport, isTabletViewport, observeViewport } from '@/hooks/useIsMobileViewport';
import { Colors } from '@/styles/base/colors';

import { FullscreenStoreProvider, useCreateFullscreenStore, useFullscreenStore } from '../media-player/states';

import { getRouteFromPathname } from './routes';
import { ROUTE_THEME } from './routes.config';

function InternalRootRoute() {
  const fullscreenStore = useFullscreenStore();
  const { pathname } = useLocation();
  const route = useMemo(() => getRouteFromPathname(pathname), [pathname]);
  const routeTheme = route ? ROUTE_THEME[route] : null;

  useLayoutEffect(() => {
    document.body.setAttribute('data-mobile', String(isMobileViewport()));
    document.body.setAttribute('data-tablet', String(isTabletViewport()));
    return observeViewport((isMobile: boolean, isTablet: boolean) => {
      document.body.setAttribute('data-mobile', String(isMobile));
      document.body.setAttribute('data-tablet', String(isTablet));
    });
  }, []);

  useLayoutEffect(() => {
    document.body.setAttribute('data-fullscreen', String(isFullScreen(fullscreenStore)));
    return observeIsFullscreen(fullscreenStore, (isFullscreen) => {
      document.body.setAttribute('data-fullscreen', String(isFullscreen));
    });
  }, [fullscreenStore]);

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={routeTheme ?? Colors.spruce[15]} />
      </Helmet>
      <Outlet />
    </>
  );
}

export function RootRoute() {
  const createFullscreenStore = useCreateFullscreenStore();
  const fullscreenStore = useMemo(() => createFullscreenStore(), [createFullscreenStore]);

  return (
    <FullscreenStoreProvider value={fullscreenStore}>
      <InternalRootRoute />
    </FullscreenStoreProvider>
  );
}
