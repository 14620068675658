export const Colors = {
  white: {
    '100-8': 'hsla(0, 0%, 100%, 0.08)',
    '100-12': 'hsla(0, 0%, 100%, 0.12)',
  },
  neutral: {
    50: 'hsla(50, 18%, 50%, 1)',
    '50-8': 'hsla(50, 18%, 50%, 0.08)',
    '50-12': 'hsla(50, 18%, 50%, 0.12)',
    '50-16': 'hsla(50, 18%, 50%, 0.16)',
    77: 'hsla(50, 18%, 77%, 1)',
    80: 'hsla(50, 18%, 80%, 1)',
    84: 'hsla(51, 17%, 84%, 1)',
    87: 'hsla(50, 18%, 87%, 1)',
    90: 'hsla(47, 18%, 90%, 1)',
    93: 'hsla(50, 17%, 93%, 1)',
    96: 'hsla(45, 20%, 96%, 1)',
    '96-8': 'hsla(50, 18%, 50%, 0.08)',
    '96-12': 'hsla(50, 18%, 50%, 0.12)',
    '96-16': 'hsla(50, 18%, 50%, 0.16)',
    99: 'hsla(45, 20%, 99%, 1)',
  },
  spruce: {
    10: 'hsla(180, 22%, 10%, 1)',
    12: 'hsla(180, 22%, 12%, 1)',
    15: 'hsla(180, 22%, 15%, 1)',
    20: 'hsla(180, 22%, 20%, 1)',
    30: 'hsla(180, 22%, 30%, 1)',
    40: 'hsla(180, 22%, 40%, 1)',
    50: 'hsla(180, 22%, 50%, 1)',
    '40-8': 'hsla(180, 22%, 40%, 0.08)',
    '40-12': 'hsla(180, 22%, 40%, 0.12)',
    '40-16': 'hsla(180, 22%, 40%, 0.16)',
    60: 'hsla(180, 22%, 60%, 1)',
  },
  blend: {
    30: 'hsla(105, 2%, 69%, 1)',
    69: 'hsla(105, 3%, 69%, 1)',
    50: 'hsla(144, 2%, 52%, 1)',
    70: 'hsla(171, 4%, 35%, 1)',
    90: 'hsla(174, 11%, 18%, 1)',
  },
  aqua: {
    70: 'hsla(170, 32%, 70%, 1)',
    '70-8': 'hsla(170, 32%, 70%, 0.08)',
    '70-12': 'hsla(170, 32%, 70%, 0.12)',
    '70-16': 'hsla(170, 32%, 70%, 0.16)',
    80: 'hsla(171, 31%, 80%, 1)',
    90: 'hsla(169, 33%, 90%, 1)',
  },
  yellow: {
    15: 'hsla(64, 82%, 15%, 1)',
    20: 'hsla(64, 80%, 20%, 1)',
    30: 'hsla(64, 80%, 30%, 1)',
    40: 'hsla(64, 81%, 40%, 1)',
    50: 'hsla(64, 81%, 50%, 1)',
    60: 'hsla(64, 81%, 60%, 1)',
    70: 'hsla(64, 80%, 70%, 1)',
    80: 'hsla(64, 80%, 80%, 1)',
  },
  red: {
    25: 'hsla(347, 94%, 25%, 1)',
    40: 'hsla(347, 94%, 40%, 1)',
    60: 'hsla(347, 94%, 60%, 1)',
    80: 'hsla(347, 94%, 80%, 1)',
    90: 'hsla(348, 96%, 90%, 1)',
    95: 'hsla(348, 100%, 95%, 1)',
  },
  black: {
    0: 'hsla(0, 100%, 0%, 1)',
    '0-25': 'hsla(0, 100%, 0%, 0.25)',
    '0-50': 'hsla(0, 100%, 0%, 0.50)',
    '0-70': 'hsla(0, 100%, 0%, 0.70)',
    '0-90': 'hsla(0, 100%, 0%, 0.90)',
  },
  orange: {
    35: 'hsla(20, 76%, 35%, 1)',
  },
  green: {
    30: 'hsl(146, 55%, 30%)',
  },
} as const;
