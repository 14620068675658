import { PropsWithChildren, ReactNode } from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';

import { RMText } from '../RMText/RMText';

import { TooltipContent } from './RMTooltip.styles';

type RMTooltipProps = PropsWithChildren<{
  message?: string | ReactNode;
  side?: 'right' | 'top' | 'bottom' | 'left';
  align?: 'start' | 'center' | 'end';
}>;

export function RMTooltip({ children, message, side = 'right', align = 'center' }: RMTooltipProps) {
  if (!message) {
    return <>{children}</>;
  }

  return (
    <Tooltip.Provider delayDuration={0.2}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>

        <Tooltip.Portal>
          <Tooltip.Content sideOffset={12} align={align} side={side} style={{ zIndex: 999 }}>
            <TooltipContent
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ ease: 'easeOut', duration: 0.2 }}
            >
              {typeof message === 'string' ? (
                <RMText type="sans" size="xxs" color="inverse-on-surface-primary">
                  {message}
                </RMText>
              ) : (
                message
              )}
            </TooltipContent>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
