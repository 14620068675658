import { Asset, AssetMutationType, UnlinkEntityMutation } from '@remento/types/asset';
import { EntityResponse, EntityType } from '@remento/types/entity';

import { AssetEntity, AssetService, CreateAssetPayload, CreateFilestackAssetPayload } from '@/services/api/asset';

import { api } from '../api';
import { RequestScope } from '../api.types';
import { AuthService } from '../auth/auth.types';
import { AuthorizationService } from '../authorization';
import { EntityMutation } from '../cache';

export class DefaultAssetService implements AssetService {
  constructor(private authService: AuthService, private authorizationService: AuthorizationService) {}

  async getAsset(assetId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/assets/${assetId}`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getAssetAlternatives(assetId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/assets/${assetId}/alternatives`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getAlternative(alternativeId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/alternatives/${alternativeId}`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  private async getAlternativeUrlParams(): Promise<URLSearchParams> {
    const params = new URLSearchParams();

    const authToken = await this.authService.getAuthToken();
    if (authToken) {
      params.set('authToken', `Bearer ${authToken}`);
    }

    const tokens = this.authorizationService.getPermissionTokens();
    for (const token of tokens) {
      params.append('token', token);
    }

    return params;
  }

  async getAlternativeFileUrl(alternativeId: string): Promise<string> {
    const url = api.getUri({
      url: `/alternatives/${alternativeId}/file`,
      params: await this.getAlternativeUrlParams(),
    });

    return url;
  }

  async getAlternativeVideoUrl(alternativeId: string): Promise<string> {
    const url = api.getUri({
      url: `/alternatives/${alternativeId}/video`,
      params: await this.getAlternativeUrlParams(),
    });

    return url;
  }

  async getAlternativeDownloadVideoUrl(alternativeId: string, scope?: RequestScope): Promise<string> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<{ url: string }>(`/alternatives/${alternativeId}/video/download`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data.url;
  }

  async createAsset(payload: CreateAssetPayload): Promise<Asset> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();

    const formData = new FormData();
    formData.set('data', JSON.stringify(payload.data));
    formData.set('asset', payload.file);

    const { data } = await api.post<Asset>('/assets', formData, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });

    // DO NOT CACHE, the newly created asset has no useful alternatives
    return data;
  }

  async createFilestackAsset(payload: CreateFilestackAssetPayload): Promise<Asset> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post<Asset>('/assets/filestack', payload, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
    return data;
  }

  createUnlinkEntityMutation(asset: Asset, entity: AssetEntity): EntityMutation<UnlinkEntityMutation>[] {
    return [
      {
        type: EntityType.ASSET,
        id: asset.id,
        mutations: [
          {
            type: AssetMutationType.UNLINK_ENTITY,
            value: entity,
            vclock: asset.vclock,
            version: asset.version,
          },
        ],
      },
    ];
  }
}
