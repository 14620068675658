import { useCallback, useMemo } from 'react';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { PersonName } from '@remento/types/person';

import { PageLoader } from '@/components/PageLoader/PageLoader';
import { useAsyncEffect } from '@/hooks';
import { RecordingLayout } from '@/modules/recording/layouts/RecordingLayout';
import { getSigninPath, RementoPage } from '@/modules/routing';
import { RoutePath } from '@/modules/routing/types/routing.types';
import { routes } from '@/routes';
import { useServices } from '@/Services';
import { useSignOut, useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';

import { UnauthorizedPage } from '../components/UnauthorizedPage/UnauthorizedPage';

export interface SignedInUser {
  name: PersonName | null;
  email: string;
}

interface InternalUnauthorizedPageContainerProps {
  isRecordingPage: boolean;
}

function InternalUnauthorizedPageContainer({ isRecordingPage }: InternalUnauthorizedPageContainerProps) {
  const { redirectService } = useServices();

  const navigate = useNavigate();
  const user = useUser();
  const signOut = useSignOut();

  const personQuery = usePersonQuery(user?.personId);
  const signedInUser = useMemo(() => {
    if (user == null || personQuery?.data == null) {
      return null;
    }

    const name = personQuery?.data?.name ?? null;
    const email = user?.communicationChannels.email ?? '';

    return {
      name,
      email,
    };
  }, [user, personQuery?.data]);

  const handleRedirect = useCallback(async () => {
    if (user) {
      navigate('/');
      return;
    }
    await redirectService.registerRedirect('signed-in', window.location.pathname + window.location.search);
    navigate(getSigninPath());
  }, [navigate, redirectService, user]);

  useAsyncEffect(
    async (checkpoint) => {
      if (user == null) {
        await redirectService.registerRedirect('signed-in', window.location.pathname + window.location.search);
        checkpoint();
        navigate(getSigninPath());
      }
    },
    [navigate, redirectService, user],
  );

  if (user == null) {
    return <PageLoader />;
  }

  return (
    <UnauthorizedPage
      isRecordingPage={isRecordingPage}
      user={signedInUser}
      onRedirect={handleRedirect}
      onSignOut={signOut}
    />
  );
}

export function UnauthorizedPageContainer() {
  const location = useLocation();

  const isRecordingPage = useMemo(() => {
    const routeMatch = matchRoutes(routes, location)?.find((r) => r.pathname === location.pathname);
    if (!routeMatch) {
      return false;
    }
    switch (routeMatch.route.path as RoutePath) {
      case RoutePath.RecordingIntro:
      case RoutePath.RecordingTypeSelection:
      case RoutePath.Recording:
      case RoutePath.RecordingFinish:
        return true;
      default:
        return false;
    }
  }, [location]);

  return (
    <RementoPage
      type="error"
      loader={isRecordingPage ? <RecordingLayout.Root dark Header={<RecordingLayout.Header dark />} /> : <PageLoader />}
    >
      <InternalUnauthorizedPageContainer isRecordingPage={isRecordingPage} />
    </RementoPage>
  );
}
