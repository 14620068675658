import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { notNull } from '@remento/utils/array/notNull';

import { AnalyticsRepository, AnalyticsUserProps, Page } from '@/services/analytics/analytics.types';
import { DatadogTracingService, ErrorListener } from '@/services/analytics/datadog/datadog-tracing.types';
import { UserIdentifier } from '@/services/api/user/user.types';

import * as pkg from '../../../../package.json';

export class DatadogTracingServiceImpl implements DatadogTracingService, AnalyticsRepository {
  private errorListeners = new Set<ErrorListener>();

  constructor() {
    const applicationId = import.meta.env.VITE_DATADOG_APPLICATION_ID;
    const clientToken = import.meta.env.VITE_DATADOG_TOKEN;

    if (!applicationId || !clientToken) {
      console.warn('Datadog applicationId and clientToken are not set');
      return;
    }

    datadogLogs.init({
      clientToken: clientToken,
      site: import.meta.env.VITE_DATADOG_SITE,
      service: 'remento-book-webapp',
      env: import.meta.env.VITE_ENV,
      version: pkg.version,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
    });

    datadogRum.init({
      applicationId,
      clientToken,
      site: import.meta.env.VITE_DATADOG_SITE,
      service: 'remento-book-webapp',
      env: import.meta.env.VITE_ENV,
      sessionSampleRate: 100,
      premiumSampleRate: 100,
      trackUserInteractions: true,
      version: pkg.version,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [
        import.meta.env.VITE_API_URL,
        import.meta.env.VITE_STREAMING_WS_URL,
        import.meta.env.VITE_CONVERSATION_WS_URL,
      ].filter(notNull),
      beforeSend: (event) => {
        if (event.type === 'error') {
          event.error.fingerprint = event.error.message;

          if (event.error.id == null) return;

          const now = Date.now();
          const errorUrl = `https://${import.meta.env.VITE_DATADOG_SITE}/rum/error-tracking?query=%40error.id%3A${
            event.error.id
          }&from_ts=${new Date(now - 3600000).getTime()}&to_ts=${new Date(now + 3600000).getTime()}`;

          this.errorListeners.forEach((cb) => cb(errorUrl));
        }
      },
    });

    datadogRum.startSessionReplayRecording();
  }

  getTracingData(): { sessionId: string; viewId: string } | undefined {
    const contextData = datadogRum.getInternalContext();
    const sessionId = contextData?.session_id;
    const viewId = contextData?.view?.id;

    if (sessionId == null || viewId == null) return;

    return {
      viewId,
      sessionId,
    };
  }

  wrapWebSocketUrl(url: URL): URL {
    const tracingData = this.getTracingData();

    if (tracingData != null) {
      url.searchParams.append('tracing', JSON.stringify(tracingData));
    }

    return url;
  }

  // Datadog traces errors automatically. This method exists to create events
  // that are linked to the error logs and send them to the analytics services.
  // We could take the analytics service in the constructor but that would imply
  // running code before the tracing starts and that is risky, since something
  // could happen, and we would have no trace of it.
  onError(callback: ErrorListener) {
    this.errorListeners.add(callback);
    return () => this.errorListeners.delete(callback);
  }

  initialize(userIdentifier: UserIdentifier): void {
    if (userIdentifier.type === 'session') {
      datadogRum.setUser({ id: userIdentifier.id, segment: 'anonymous' });
      datadogLogs.setUser({ id: userIdentifier.id, segment: 'anonymous' });
    }
  }

  private rumIdentify(userId: string, user: AnalyticsUserProps): void {
    const currentUser = datadogRum.getUser();
    if (currentUser.id === userId && currentUser.segment !== 'anonymous' && currentUser.name && currentUser.email) {
      return;
    }

    datadogRum.setUser({
      id: userId,
      name: user.name ?? undefined,
      firstName: user.firstName ?? undefined,
      lastName: user.lastName ?? undefined,
      email: user.email ?? undefined,
      segment: 'credential',
    });
  }

  private logsIdentify(userId: string, user: AnalyticsUserProps): void {
    const currentUser = datadogLogs.getUser();
    if (currentUser.id === userId && currentUser.segment !== 'anonymous' && currentUser.name && currentUser.email) {
      return;
    }

    datadogLogs.setUser({
      id: userId,
      name: user.name ?? undefined,
      firstName: user.firstName ?? undefined,
      lastName: user.lastName ?? undefined,
      email: user.email ?? undefined,
      segment: 'credential',
    });
  }

  identify(userId: string, user: AnalyticsUserProps): void {
    this.logsIdentify(userId, user);
    this.rumIdentify(userId, user);
  }

  track(event: string, payload?: Record<string, unknown>): void {
    datadogRum.addAction(event, payload);
  }

  page(page: Page, payload?: Record<string, unknown>): void {
    datadogRum.addAction('page', payload);
  }

  reset(): void {
    datadogRum.removeUser();
    datadogLogs.clearUser();
  }
}
