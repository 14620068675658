import { PropsWithChildren, ReactNode, useEffect } from 'react';

import { PageLoader } from '@/components/PageLoader/PageLoader';
import { getQueryParam } from '@/hooks/useQueryParam';
import { ABProvider } from '@/modules/ab/ABProvider';
import { OnboardingDialogContainer } from '@/modules/onboarding-dialog/containers/OnboardingDialog.container';
import { PaywallContainer } from '@/modules/paywall';
import { useValidateRoute } from '@/modules/routing';
import { ServicesProvider } from '@/Services';
import { getMarketingCookies } from '@/utils/marketing-cookies';

import { getRouteFromPathname } from '../routes';
import { RoutePageEventMap } from '../types/routing.analytics.types';
import { RouteType } from '../types/routing.types';

import { PageContent } from './RementoPage.styles';

export type ProtectedPageProps = PropsWithChildren<{
  type: RouteType;
  loader?: ReactNode;
  pageAnalyticsPayload?: Record<string, unknown>;
}>;

export function RementoPage({ type, loader = <PageLoader />, pageAnalyticsPayload, children }: ProtectedPageProps) {
  const result = useValidateRoute(type);

  // Send the page arrive analytics event.
  // The event should NOT be sent when we are rendering the error state
  // or the user has no permission to access the page and will be redirected.
  useEffect(() => {
    if (type === 'error' || result.status !== 'valid') {
      return;
    }

    const route = getRouteFromPathname(location.pathname);
    const analyticsPage = route != null ? RoutePageEventMap[route] : null;
    if (analyticsPage == null) {
      return;
    }
    result.services.webappAnalyticsService.onPageArrived(analyticsPage, {
      ...pageAnalyticsPayload,
      ...getMarketingCookies(),
      referrerRoute: getQueryParam('referrer'),
    });
  }, [pageAnalyticsPayload, result, type]);

  // Keep showing the loader if the user does not have permission even
  // if it's not loading anymore, because in that case the user will be redirected.
  if (result.status === 'validating' || result.status === 'invalid') {
    return <>{loader}</>;
  }

  return (
    <ServicesProvider services={result.services}>
      <ABProvider>
        {result.status === 'revalidating' && <>{loader}</>}
        {/* We need this to keep the context of the children alive when we are revalidating the page */}
        <PageContent data-loading={result.status === 'revalidating'}>
          {children}
          {type !== 'error' && <OnboardingDialogContainer />}
        </PageContent>
        <PaywallContainer />
      </ABProvider>
    </ServicesProvider>
  );
}
