import { useCallback, useMemo } from 'react';
import { UserTag } from '@remento/types/user';
import { z } from 'zod';

import { toast } from '@/components/RMToast/RMToast';
import { createForm, submitForm } from '@/modules/form/form';
import { useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery, useProjectsQuery } from '@/services/api/project';
import { CustomOnboardingDialogRenderProps } from '@/services/local/onboarding-dialog';
import { captureException } from '@/utils/captureException';

import { PhoneCollectionOnboardingDialog } from '../components/PhoneCollectionOnboardingDialog/PhoneCollectionOnboardingDialog';

export function PhoneCollectionOnboardingDialogContainer({ onSetOnboardingDone }: CustomOnboardingDialogRenderProps) {
  // Services
  const { userService, entityCacheManagerService } = useServices();
  const user = useUser();

  // Queries
  const projectsQuery = useProjectsQuery();
  const firstProjectQuery = useProjectQuery(projectsQuery.data?.[0]);
  const storytellerQuery = usePersonQuery(firstProjectQuery.data?.subjectPersonIds[0]);

  // State
  const isHostOrStoryteller = useMemo(
    () => user?.tags.includes(UserTag.HOST) || user?.tags.includes(UserTag.STORYTELLER),
    [user?.tags],
  );

  const form = useMemo(
    () =>
      createForm({
        validation: {
          mode: 'onChange',
          display: 'onTouched',
        },
        schema: z.object({
          phone: z.string().length(10, 'Please enter a valid phone number'),
        }),
        defaultValues: {
          phone: '',
        },
      }),
    [],
  );

  // Callbacks
  const handleSubmit = useCallback(async () => {
    if (user == null) {
      // This will never happen.
      return;
    }

    await submitForm(form, async ({ phone }) => {
      try {
        await entityCacheManagerService.mutate([
          ...userService.createSetUserPhoneMutation(user, {
            countryCode: '1',
            number: phone,
          }),
        ]);
        await userService.refreshUser();
        await onSetOnboardingDone();
      } catch (error) {
        captureException(error, true);
        toast('An unexpected error has occurred.', 'root-toast', 'error');
      }
    });
  }, [entityCacheManagerService, form, onSetOnboardingDone, user, userService]);

  const handleSkip = useCallback(async () => {
    try {
      await onSetOnboardingDone();
    } catch (error) {
      captureException(error, true);
      toast('An unexpected error has occurred.', 'root-toast', 'error');
    }
  }, [onSetOnboardingDone]);

  return (
    <PhoneCollectionOnboardingDialog
      form={form}
      storytellerName={isHostOrStoryteller ? null : storytellerQuery.data?.name?.first ?? null}
      onSubmit={handleSubmit}
      onSkip={handleSkip}
    />
  );
}
