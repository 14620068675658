import { ComponentProps, Ref } from 'react';
import { IMaskMixin } from 'react-imask';

import { RMInput, RMInputProps } from '../RMInput/RMInput';

const MaskedInput = IMaskMixin(({ inputRef, id = '', ...props }) => {
  return <RMInput id={id} {...props} ref={inputRef as Ref<HTMLInputElement>} />;
});

type MaskProps = ComponentProps<typeof MaskedInput>;

export const RMInputMasked = (props: RMInputProps & MaskProps) => {
  return <MaskedInput {...props} />;
};
