import { api } from '@/services/api/api';

import { RecordingSessionService, RecordingSessionUrl } from './recording-session.types';

export class DefaultRecordingSessionService implements RecordingSessionService {
  async getRecordingSessionUrl(sessionId: string): Promise<RecordingSessionUrl> {
    const { data } = await api.post<RecordingSessionUrl>(`/recording-sessions/${sessionId}`);
    return data;
  }

  async processRecordingSession(sessionId: string, promptId: string): Promise<void> {
    await api.post<RecordingSessionUrl>(`/recording-sessions/${sessionId}/process`, { promptId });
  }
}
