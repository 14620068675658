export const ColorsTokens = {
  primary: 'var(--color-spruce-40)',
  'primary-decorative': 'var(--color-spruce-50)',
  secondary: 'var(--color-yellow-50)',
  'secondary-gradient-top-bottom': 'linear-gradient(180deg, var(--color-yellow-80) 0%, var(--color-yellow-60) 100%)',
  'secondary-gradient-bottom-top': 'linear-gradient(0deg, var(--color-yellow-80) 0%, var(--color-yellow-60) 100%)',
  'secondary-gradient-right-left': 'linear-gradient(-90deg, var(--color-yellow-80) 0%, var(--color-yellow-60) 100%)',
  'secondary-gradient-left-right': 'linear-gradient(90deg, var(--color-yellow-80) 0%, var(--color-yellow-60) 100%)',
  tertiary: 'var(--color-aqua-70)',
  'on-primary': 'var(--color-neutral-96)',
  'on-secondary': 'var(--color-spruce-20)',
  'on-tertiary': 'var(--color-spruce-20)',
  surface: 'var(--color-neutral-93)',
  'surface-dim': 'var(--color-neutral-90)',
  'surface-bright': 'var(--color-neutral-96)',
  'surface-brightest': 'var(--color-neutral-99)',
  'surface-ghost': 'var(--color-neutral-77)',
  'surface-always-dark': 'var(--color-spruce-10)',
  'inverse-surface': 'var(--color-spruce-12)',
  'inverse-on-surface-primary': 'var(--color-neutral-90)',
  'inverse-on-surface-secondary': 'var(--color-blend-69)',
  'inverse-primary': 'var(--color-aqua-70)',
  'inverse-on-primary': 'var(--color-spruce-20)',
  'on-surface-primary': 'var(--color-spruce-20)',
  'on-surface-secondary': 'var(--color-spruce-30)',
  'on-surface-tertiary': 'var(--color-blend-50)',
  'border-primary': 'var(--color-spruce-40)',
  'border-strong': 'var(--color-blend-30)',
  'border-stronger': 'var(--color-blend-70)',
  'border-weak': 'var(--color-neutral-80)',
  'border-hairline': 'var(--color-neutral-87)',
  'inverse-border-strong': 'var(--color-blend-50)',
  'inverse-border-weak': 'var(--color-white-100-12)',
  'inverse-border-primary': 'var(--color-aqua-70)',
  'inverse-border-hairline': 'var(--color-aqua-70-8)',
  'border-gradient-top-bottom': 'linear-gradient(180deg, var(--color-yellow-50) 0%, var(--color-yellow-40) 100%)',
  'border-gradient-bottom-top': 'linear-gradient(0deg, var(--color-yellow-50) 0%, var(--color-yellow-40) 100%)',
  'border-gradient-right-left': 'linear-gradient(-90deg, var(--color-yellow-50) 0%, var(--color-yellow-40) 100%)',
  'border-gradient-left-right': 'linear-gradient(90deg, var(--color-yellow-50) 0%, var(--color-yellow-40) 100%)',
  danger: 'var(--color-red-25)',
  'danger-on-inverse-surface': 'var(--color-red-80)',
  'on-danger': 'var(--color-neutral-96)',
  'danger-container': 'var(--color-red-90)',
  'on-danger-container': 'var(--color-red-25)',
  caution: 'var(--color-orange-35)',
  recording: 'var(--color-red-40)',
  'on-recording': 'var(--color-neutral-96)',
  disabled: 'var(--color-blend-69)',
  'darken-primary-hover': 'var(--color-spruce-40-8)',
  'darken-primary-focus': 'var(--color-spruce-40-12)',
  'darken-primary-press': 'var(--color-spruce-40-12)',
  'darken-primary-drag': 'var(--color-spruce-40-16)',
  'darken-neutral-hover': 'var(--color-neutral-50-8)',
  'darken-neutral-focus': 'var(--color-neutral-50-12)',
  'darken-neutral-press': 'var(--color-neutral-50-12)',
  'darken-neutral-drag': 'var(--color-neutral-50-16)',
  'lighten-primary-hover': 'var(--color-aqua-70-8)',
  'lighten-primary-focus': 'var(--color-aqua-70-12)',
  'lighten-primary-press': 'var(--color-aqua-70-12)',
  'lighten-primary-drag': 'var(--color-aqua-70-16)',
  'lighten-neutral-hover': 'var(--color-neutral-96-8)',
  'lighten-neutral-focus': 'var(--color-neutral-96-12)',
  'lighten-neutral-press': 'var(--color-neutral-96-12)',
  'lighten-neutral-drag': 'var(--color-neutral-96-16)',
  'lighten-white-hover': 'var(--color-white-100-8)',
  'lighten-white-press': 'var(--color-white-100-12)',
  scrollbar: 'var(--color-blend-69)',
  affirmative: 'var(--color-green-30)',
  'informational-container': 'var(--color-spruce-15)',
  'banner-info': 'var(--color-aqua-80)',
  'gray-avatar': 'var(--color-blend-30)',

  'book-text': 'var(--color-spruce-10)',

  'scrim-weak': 'var(--color-black-0-25)',
  'scrim-medium': 'var(--color-black-0-50)',
  'scrim-strong': 'var(--color-black-0-70)',
  transparent: 'transparent',
  black: 'var(--color-black-0',
} as const;
