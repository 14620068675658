import PhoneSmsIconSrc from '@/assets/phone-sms.svg';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMInputMasked } from '@/components/RMInputMasked/RMInputMasked';
import { RMText } from '@/components/RMText/RMText';
import { Form } from '@/modules/form/@types';
import { InputController } from '@/modules/form/container';
import { useIsFormValid } from '@/modules/form/form';

import {
  Content,
  Header,
  PhoneIcon,
  StyledPhoneCollectionOnboardingDialog,
  TextWrapper,
  Wordmark,
} from './PhoneCollectionOnboardingDialog.styles';

export interface PhoneCollectionOnboardingDialogProps {
  form: Form<{ phone: string }>;
  storytellerName: string | null;
  onSubmit: () => void;
  onSkip: () => void;
}

export function PhoneCollectionOnboardingDialog({
  form,
  storytellerName,
  onSubmit,
  onSkip,
}: PhoneCollectionOnboardingDialogProps) {
  const isFormValid = useIsFormValid(form);

  return (
    <StyledPhoneCollectionOnboardingDialog>
      <Header>
        <Wordmark />
      </Header>
      <Content>
        <PhoneIcon src={PhoneSmsIconSrc} />
        <TextWrapper>
          <RMText type="serif" size="l" color="on-surface-primary" align="center">
            {storytellerName != null
              ? `Never miss a story from ${storytellerName}`
              : 'Creating your book just got easier'}
          </RMText>
          <RMText type="sans" size="s" color="on-surface-primary" align="center">
            {storytellerName != null
              ? 'When you sign up for text messages, we let you know every time a new story is recorded.'
              : 'When you sign up for text message reminders, completing your next story is a breeze.'}
          </RMText>
        </TextWrapper>
        <TextWrapper>
          <InputController form={form} path="phone">
            {(props) => (
              <RMInputMasked
                id="user-phone-number"
                label="Your phone number"
                placeholder="(XXX) XXX-XXXX"
                mask="(000) 000-0000"
                autoCapitalize="none"
                inputMode="tel"
                value={props.value ?? ''}
                error={props.error}
                unmask={true}
                onBlur={props.onBlur}
                onAccept={(unmaskedValue: string) => {
                  if (unmaskedValue !== props.value) {
                    props.onChange(unmaskedValue);
                  }
                }}
              />
            )}
          </InputController>
          <RMButton background="primary" autoLoading fullWidth disabled={isFormValid == false} onClick={onSubmit}>
            Yes, sign me up!
          </RMButton>
          <RMButton background="none" autoLoading fullWidth onClick={onSkip}>
            No, I’ll stick with email reminders
          </RMButton>
        </TextWrapper>
        <RMText type="sans" size="xxs" color="on-surface-tertiary" align="center">
          Standard text message rates may apply. Unsubscribe any time by messaging STOP or disabling in your account
          settings. We accept USA and Canada phone numbers.
        </RMText>
      </Content>
    </StyledPhoneCollectionOnboardingDialog>
  );
}
