import { useEffect, useMemo, useState } from 'react';
import { EntityResponse, EntityTypeMapping } from '@remento/types/entity';
import { notNull } from '@remento/utils/array/notNull';
import { useQueries, useQuery as useReactQuery, UseQueryResult } from '@tanstack/react-query';

import { useServices } from '@/Services';
import { CollectionId, CompareFn, QueryFn } from '@/services/api/cache';

export function useEntity<T extends keyof EntityTypeMapping>(
  type: T,
  id: string | null | undefined,
  fn: QueryFn<string>,
  compareFn?: CompareFn<T> | null,
  throwOnError?: (error: Error) => boolean,
): UseQueryResult<EntityTypeMapping[T]> {
  const { entityCacheManagerService } = useServices();

  return useReactQuery({
    ...(id
      ? {
          ...entityCacheManagerService.buildEntityQuery<T>(type, id, fn, compareFn ?? undefined),
          throwOnError,
        }
      : {
          queryKey: [type],
          enabled: false,
        }),
  });
}

/**
 * Hook for fetching an entity by a foreign key.
 * We need the primary ID of the entity to correctly create the cache entry.
 * So in addition to the normal fetch callback,
 * a callback that returns the primary ID of the entity must be provided.
 *
 * @example
 * ```typescript
 * // Fetch a story by promptId.
 * return useEntityByForeignId(
 *   EntityType.STORY,
 *   promptId,
 *   async (id) => {
 *     const story = await storyCacheService.getPromptStory(id);
 *     return story?.id ?? null;
 *   },
 *   (storyId, scope) => storyService.getStory(storyId, scope),
 * );
 * ```
 */
export function useEntityByForeignId<T extends keyof EntityTypeMapping>(
  type: T,
  foreignId: string | null | undefined,
  fetchPrimaryEntityId: (foreignId: string) => Promise<string | null>,
  fn: QueryFn<string>,
  compareFn?: CompareFn<T> | null,
  throwOnError?: (error: Error) => boolean,
): UseQueryResult<EntityTypeMapping[T]> {
  const { entityCacheManagerService } = useServices();

  // We need the entity id to be able to correctly get the up to date value.
  // If we use other id, when the entity is invalidated, this query would still return the old value.
  // To workaround that, we need to fetch the user imperatively only to get the id (that is static)
  // and then use the hook to return the full entity.
  const [primaryId, setPrimaryId] = useState<string | null>(null);
  useEffect(() => {
    if (foreignId == null) {
      setPrimaryId(null);
      return;
    }

    fetchPrimaryEntityId(foreignId)
      .then(setPrimaryId)
      .catch((error) => {
        return Promise.reject(error);
      });
  }, [fetchPrimaryEntityId, foreignId]);

  return useReactQuery({
    ...(primaryId
      ? {
          ...entityCacheManagerService.buildEntityQuery<T>(type, primaryId, fn, compareFn ?? undefined),
          throwOnError,
        }
      : {
          queryKey: [type],
          enabled: false,
        }),
  });
}

export function useCollection<T extends keyof EntityTypeMapping>(
  type: T,
  id: CollectionId | null,
  fn: QueryFn<CollectionId>,
) {
  const { entityCacheManagerService } = useServices();

  return useReactQuery({
    ...(id ? entityCacheManagerService.buildCollectionQuery<T>(type, id, fn) : { queryKey: [type], enabled: false }),
  });
}

export function useCollectionEntityData<T extends keyof EntityTypeMapping>(
  ids: string[] | null | undefined,
  type: T,
  fn: (id: string) => Promise<EntityResponse>,
) {
  const { entityCacheManagerService } = useServices();

  return useQueries({
    combine: (queries) => queries.map((q) => q.data).filter(notNull),
    queries: useMemo(
      () =>
        (ids ?? []).map((id) => {
          return entityCacheManagerService.buildEntityQuery(type, id, () => fn(id));
        }),
      [entityCacheManagerService, ids, type, fn],
    ),
  });
}
