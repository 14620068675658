import { StoryStatus } from '@remento/types/story';

import { Services } from '@/Services';

import { getRecordingPromptRecordedPath } from '../paths';
import { ActiveRoute, PageErrorRedirect, PageRedirect, RoutePath } from '../types/routing.types';

export async function validateRecordRoute(route: ActiveRoute, services: Services): Promise<void> {
  switch (route.route) {
    case RoutePath.Recording:
    case RoutePath.RecordingIntro:
    case RoutePath.RecordingTypeSelection:
      break;
    default:
      throw new PageErrorRedirect();
  }

  const promptId = route.params.promptId ?? null;
  if (promptId === null) {
    throw new PageErrorRedirect();
  }

  await services.storyCacheService.loadStoryRecordByPromptId(promptId);

  const prompt = await services.projectCacheService.getPrompt(promptId);
  if (prompt === null) {
    throw new PageErrorRedirect();
  }

  const story = await services.storyCacheService.getPromptStory(prompt.id);
  if (story === null) {
    throw new PageErrorRedirect();
  }

  if (story.status === StoryStatus.PROCESSING || story.status === StoryStatus.RECORDED) {
    throw new PageRedirect(getRecordingPromptRecordedPath(route.params.projectId ?? '', route.params.promptId ?? ''));
  }
}
