import { Story, StoryDataType } from '@remento/types/story';

export function getStoryTitle(story?: Story | null) {
  return story?.title == null || story.title.type == StoryDataType.TRANSCRIPT_TOO_SHORT ? null : story.title.value;
}

export function getStorySummary(story?: Story | null) {
  return story?.summary == null || story.summary.type == StoryDataType.TRANSCRIPT_TOO_SHORT
    ? null
    : story.summary.value;
}
