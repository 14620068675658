import { notNull } from '@remento/utils/array/notNull';

import { logger } from '@/logger';
import { getLocalStoreKey, LocalStoreRepository } from '@/services/local/local-store';

import {
  CreateInterviewSessionData,
  INTERVIEW_SESSION_EXPIRATION_TIME_MS,
  InterviewSession,
  InterviewSessionRepository,
} from './interview-session.types';

export class LocalInterviewSessionRepository implements InterviewSessionRepository {
  constructor(private localStore: LocalStoreRepository) {}

  createSession(session: CreateInterviewSessionData): void {
    this.localStore.setItem<InterviewSession>(this.getLocalStoreFieldKey(session.sessionId), {
      ...session,
      bytesSent: 0,
      duration: 0,
      promptSentTimestamp: 0,
      finished: false,
      paused: true,
    });
    this.addIndexedSessionId(session.sessionId);
  }

  setFinished(sessionId: string): void {
    const session = this.getInterviewSession(sessionId);
    if (session === null) {
      throw new Error('Session not found');
    }
    this.localStore.setItem<InterviewSession>(this.getLocalStoreFieldKey(session.sessionId), {
      ...session,
      finished: true,
    });
  }

  setPaused(sessionId: string, paused: boolean): void {
    const session = this.getInterviewSession(sessionId);
    if (session === null) {
      throw new Error('Session not found');
    }
    this.localStore.setItem<InterviewSession>(this.getLocalStoreFieldKey(session.sessionId), {
      ...session,
      paused,
    });
  }

  setBytesSent(sessionId: string, bytesSent: number): void {
    const session = this.getInterviewSession(sessionId);
    if (session === null) {
      throw new Error('Session not found');
    }
    this.localStore.setItem<InterviewSession>(this.getLocalStoreFieldKey(session.sessionId), {
      ...session,
      bytesSent,
    });
  }

  setDuration(sessionId: string, duration: number): void {
    logger.debug('SET_DURATION', { sessionId, duration });
    const session = this.getInterviewSession(sessionId);
    if (session === null) {
      throw new Error('Session not found');
    }
    this.localStore.setItem<InterviewSession>(this.getLocalStoreFieldKey(session.sessionId), {
      ...session,
      duration,
    });
  }

  setPromptSentTimestamp(sessionId: string, promptSentTimestamp: number): void {
    logger.debug('SET_PROMPT_SENT_TIMESTAMP', { sessionId, promptSentTimestamp });
    const session = this.getInterviewSession(sessionId);
    if (session === null) {
      throw new Error('Session not found');
    }
    this.localStore.setItem<InterviewSession>(this.getLocalStoreFieldKey(session.sessionId), {
      ...session,
      promptSentTimestamp,
    });
  }

  deleteInterviewSession(sessionId: string): void {
    this.localStore.removeItem(this.getLocalStoreFieldKey(sessionId));
    this.deleteIndexedSessionId(sessionId);
  }

  getInterviewSession(sessionId: string): InterviewSession | null {
    return this.localStore.getItem(this.getLocalStoreFieldKey(sessionId));
  }

  getInterviewSessionIds(): string[] {
    return this.getIndexedSessionIds();
  }

  getOldInterviewSessions(): string[] {
    const ids = this.getInterviewSessionIds();

    const sessions = ids.map((id) => this.getInterviewSession(id)).filter(notNull);
    const oldSessions = sessions.filter(
      (s) =>
        s.uploadUrl == null || s.createdAt == null || Date.now() - s.createdAt > INTERVIEW_SESSION_EXPIRATION_TIME_MS,
    );

    return oldSessions.map((session) => session.sessionId);
  }

  private getIndexedSessionIds(): string[] {
    return this.localStore.getItem(this.getLocalStoreFieldKey('index')) ?? [];
  }

  private addIndexedSessionId(sessionId: string): void {
    const ids = new Set(this.getIndexedSessionIds());
    ids.add(sessionId);
    this.localStore.setItem(this.getLocalStoreFieldKey('index'), Array.from(ids));
  }

  private deleteIndexedSessionId(sessionId: string): void {
    const ids = new Set(this.getIndexedSessionIds());
    if (ids.delete(sessionId)) {
      this.localStore.setItem(this.getLocalStoreFieldKey('index'), Array.from(ids));
    }
  }

  private getLocalStoreFieldKey(id: string): string {
    return getLocalStoreKey(['@remento/interview-session-repository', id]);
  }
}
