import { EntityResponse, EntityType } from '@remento/types/entity';
import { SubscriptionType } from '@remento/types/user';

import { api } from '../api';
import { AuthorizationService } from '../authorization';
import { EntityCacheManagerService } from '../cache/entity-cache-manager.types';
import { UserService } from '../user/user.types';

import {
  Checkout,
  CheckoutRequest,
  CheckoutService,
  CheckoutSession,
  CheckoutTotalRequest,
  CheckoutTotalResponse,
  LegacyFinishCheckoutRequest,
} from './checkout.types';

export class DefaultCheckoutService implements CheckoutService {
  constructor(
    private userService: UserService,
    private authorizationService: AuthorizationService,
    private entityCacheManager: EntityCacheManagerService,
  ) {}

  async checkout(request: CheckoutRequest): Promise<Checkout> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();

    const { data } = await api.post<Checkout>('/checkout', request, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
    return data;
  }

  async legacyFinishCheckout(request: LegacyFinishCheckoutRequest): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post<EntityResponse>('/legacy/checkout/finish', request, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
    // TODO - Move the caching to a caching service
    await this.entityCacheManager.cacheResponse(data);

    // Invalidate user and person cache
    await this.userService.refreshUser();
    await this.entityCacheManager.invalidateCollection(EntityType.PERSON, {});

    return data;
  }

  async reactivate(type: SubscriptionType): Promise<Checkout> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post<Checkout>(
      '/checkout/reactivation',
      { type },
      {
        params: credentialsForRequest.params,
        headers: credentialsForRequest.headers,
      },
    );
    return data;
  }

  async earlyRenew(): Promise<Checkout> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post<Checkout>('/checkout/early-renewal', null, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
    return data;
  }

  async getCheckoutTotal(request: CheckoutTotalRequest): Promise<CheckoutTotalResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const params = new URLSearchParams(credentialsForRequest.params);
    params.set('projectType', request.projectType);
    params.set('books', String(request.books));
    params.set('ebook', String(request.ebook));
    if (request.coupon != null) {
      params.set('coupon', request.coupon);
    }
    const { data } = await api.get<CheckoutTotalResponse>('/checkout/total', {
      params,
      headers: credentialsForRequest.headers,
    });
    return data;
  }

  async getCheckoutSession(id: string): Promise<CheckoutSession> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<CheckoutSession>('/checkout-sessions/' + id, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
    return data;
  }
}
