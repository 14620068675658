import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import {
  getBookOrderCoverDesignPath,
  getBookOrderFinalizePath,
  getBookOrderPreviewPath,
  getBookOrderQuantityPath,
  getBookOrderStoriesPath,
  getProjectPromptsPath,
  getProjectSettingsPath,
  getStoriesPath,
} from '@/modules/routing';
import { getRouteFromPathname } from '@/modules/routing/routes';
import { RoutePath } from '@/modules/routing/types/routing.types';
import { useServices } from '@/Services';
import { usePersonQuery } from '@/services/api/person';
import { useProjectCoverUrl, useProjectInitials, useProjectQuery, useProjectsQuery } from '@/services/api/project';

import { ProjectSelect } from '../components/ProjectSelect';
import { ProjectSelectItemList } from '../components/ProjectSelectItemList/ProjectSelectItemList';
import { setNavbarProjectId, useNavbarProjectId, useNavbarStore } from '../states';

interface ProjectSelectItemContainerProps {
  projectId: string;
}

function ProjectSelectItemContainer({ projectId }: ProjectSelectItemContainerProps) {
  // Entities
  const projectQuery = useProjectQuery(projectId);
  const projectInitials = useProjectInitials(projectQuery.data);
  const projectCoverUrl = useProjectCoverUrl(projectQuery.data);
  const subjectPersonQuery = usePersonQuery(projectQuery.data?.subjectPersonIds[0]);

  // State
  const navbarStore = useNavbarStore();
  const selectedProjectId = useNavbarProjectId(navbarStore);

  if (!projectQuery.data) {
    return null;
  }

  return (
    <ProjectSelect.Item
      id={projectId}
      name={projectQuery.data.name ?? 'N/A'}
      initials={projectInitials}
      coverUrl={projectCoverUrl}
      subjectName={subjectPersonQuery.data?.name?.full ?? null}
      selected={selectedProjectId === projectId}
    />
  );
}

export interface ProjectSelectContainerProps {
  onAddProject: () => void;
}

export function ProjectSelectContainer({ onAddProject }: ProjectSelectContainerProps) {
  const isMobile = useIsMobileViewport();

  // Services
  const { webappAnalyticsService, bookCacheService } = useServices();

  // State
  const navbarStore = useNavbarStore();
  const selectedProjectId = useNavbarProjectId(navbarStore);

  // Entities
  const projectsQuery = useProjectsQuery();
  const projectQuery = useProjectQuery(selectedProjectId);
  const projectInitials = useProjectInitials(projectQuery.data);
  const projectCoverUrl = useProjectCoverUrl(projectQuery.data);
  const subjectPersonQuery = usePersonQuery(projectQuery.data?.subjectPersonIds[0]);

  const navigate = useNavigate();

  const projectIds = useMemo(() => {
    if (!selectedProjectId || !projectsQuery.data) {
      return undefined;
    }

    return projectsQuery.data.filter((id) => id !== selectedProjectId);
  }, [projectsQuery.data, selectedProjectId]);

  // Callbacks
  const handleProjectChange = useCallback(
    async (projectId: string) => {
      setNavbarProjectId(navbarStore, projectId);
      webappAnalyticsService.onProjectSwapped('global-navigation');

      // Update the current url
      const route = getRouteFromPathname(window.location.pathname);
      switch (route) {
        case RoutePath.Stories: {
          navigate(getStoriesPath(projectId));
          break;
        }
        case RoutePath.Questions: {
          navigate(getProjectPromptsPath(projectId));
          break;
        }
        case RoutePath.ProjectSettings: {
          navigate(getProjectSettingsPath(projectId));
          break;
        }
        case RoutePath.BookOrderCoverDesign: {
          const [bookId] = await bookCacheService.getBooksByProjectId(projectId);
          navigate(getBookOrderCoverDesignPath(projectId, bookId));
          break;
        }
        case RoutePath.BookOrderStories: {
          const [bookId] = await bookCacheService.getBooksByProjectId(projectId);
          navigate(getBookOrderStoriesPath(projectId, bookId));
          break;
        }
        case RoutePath.BookOrderPreview: {
          const [bookId] = await bookCacheService.getBooksByProjectId(projectId);
          navigate(getBookOrderPreviewPath(projectId, bookId));
          break;
        }
        case RoutePath.BookOrderQuantity: {
          const [bookId] = await bookCacheService.getBooksByProjectId(projectId);
          navigate(getBookOrderQuantityPath(projectId, bookId));
          break;
        }
        case RoutePath.BookOrderFinalize: {
          const [bookId] = await bookCacheService.getBooksByProjectId(projectId);
          navigate(getBookOrderFinalizePath(projectId, bookId));
          break;
        }
      }
    },
    [bookCacheService, navbarStore, navigate, webappAnalyticsService],
  );

  if (!projectQuery.data || !projectIds || !selectedProjectId) {
    return null;
  }

  return (
    <ProjectSelect.Root
      mobile={isMobile}
      selectedId={selectedProjectId}
      selectedName={projectQuery.data.name ?? 'N/A'}
      selectedPhotoUrl={projectCoverUrl}
      selectedInitials={projectInitials}
      selectedSubjectName={subjectPersonQuery.data?.name?.full ?? null}
      onAddProject={onAddProject}
      onChangeProject={handleProjectChange}
    >
      <ProjectSelectItemContainer projectId={selectedProjectId} />
      <ProjectSelectItemList>
        {projectIds.map((projectId) => (
          <ProjectSelectItemContainer key={projectId} projectId={projectId} />
        ))}
      </ProjectSelectItemList>
    </ProjectSelect.Root>
  );
}
