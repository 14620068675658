import { UserIdentifier } from '@/services/api/user/user.types';

export type AuthProvider = 'google' | 'email' | 'apple';

export type AppFlowOrigin = 'host' | 'storyteller';

export type SignInResponse = {
  user: {
    fullName: string | null;
    email: string | null;
  };
  isNewUser: boolean;
};

export enum AuthStateType {
  SignedIn = 'signed-in',
  SignedOut = 'signed-out',
}

export enum AuthStateTransition {
  SignIn = 'sign-in',
  SignOut = 'sign-out',
}

export interface BaseAuthState {
  sessionId: string;
}

export interface SignedInAuthState extends BaseAuthState {
  type: AuthStateType.SignedIn;
  userId: string;
}

export interface SignedOutAuthState extends BaseAuthState {
  type: AuthStateType.SignedOut;
}

export type AuthState = SignedInAuthState | SignedOutAuthState;

export type SignedInEvent = {
  type: AuthStateType.SignedIn;
  userId: string;
  authToken: string;
};

export interface SignedOutEvent {
  type: AuthStateType.SignedOut;
}

export type AuthChangedEvent = SignedInEvent | SignedOutEvent;
export type AuthChangedCallback = (event: AuthChangedEvent) => void;

export interface AuthService {
  initialize(): Promise<void>;

  sendEmailSignInLink(email: string, backupLocalData?: boolean): Promise<void>;
  signInWithEmail(email: string, emailLink: string, origin: AppFlowOrigin): Promise<SignInResponse>;
  signInWithGoogle(origin: AppFlowOrigin): Promise<SignInResponse>;
  signInWithApple(origin: AppFlowOrigin): Promise<SignInResponse>;
  signInWithCustomToken(jwt: string): Promise<SignInResponse>;
  signOut(): Promise<void>;

  getUserIdentifier(): UserIdentifier;
  getAuthToken(): Promise<string | null>;

  getAuthState(): AuthStateType;
  onAuthChanged(callback: AuthChangedCallback): () => void;
  onTokenChanged(callback: (token: string | null) => void): () => void;
}

export interface AuthRepository {
  getPersistedPendingStateTransition(): AuthStateTransition | null;
  persistStateTransition(transition: AuthStateTransition): void;
  deletePersistentStateTransition(): void;
  getPersistedState(): AuthStateType | null;
  persistState(state: AuthStateType): void;
  getSessionId(): string | null;
  persistSessionId(sessionId: string): void;
}
